<template>
    <div>
        <div class="learning">
            <router-view :key="$route.fullPath"/>
        </div>
    </div>
</template>
<script>
export default {
    components:{
    }
}
</script>